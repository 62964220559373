import React, {Component} from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import {
    ColumnLayout,
    Button,
    Table,
    Textarea,
    Modal,
    ButtonDropdown,
    SpaceBetween,
    Box,
    StatusIndicator
} from "@amzn/awsui-components-react";
import {Spin} from "antd";
import CONSTANT from "../utils/constant";
import APIClient from "../utils/apiClient";
import Logger from "../utils/logger";
import {
    PAGE_SELECTOR_OPTIONS,
    VISIBLE_COLUMN_OPTIONS_OPEN_TRADE,
    PAGE_SIZE,
    USER_COMMENTS_DEFINITION, HIDDEN_OPTIONS_TRADE,
    MANUAL_EXPORT_DROPDOWN_ITEMS
} from "../config/table";
import xlsxParser from '../utils/xlsxParser';
import {
    EXPORT_FILE_360T_HEADER,
    EXPORT_FILE_360T_DELIMITER,
    EXPORT_FILE_360T_BOOKTYPE,
    EXPORT_FILE_BLOOMBERG_HEADER,
    EXPORT_FILE_BLOOMBERG_DELIMITER,
    EXPORT_FILE_BLOOMBERG_BOOKTYPE
} from "../config/file";
import {FutureDateElement, DropDownElement} from "../component/dataEntry";
import {StatusComponent, PolarisV3FlashBar} from "../component/dataDisplay";
import FormatData from "../utils/formatData";
import {getStage} from "../utils/environment";
import { Container } from '@amzn/awsui-components-react';
import DataContentTable from '../component/dataContentTable';
import {NoDocumentModal} from "../document/noDocumentModal";
import {QueryDocumentData} from "../document/queryDocumentData";
import {hasDocumentAttached} from "../document/upload/documentUtils";
import SingleDocumentModule from "../document/upload/singleDocumentModule";
import {queryDocuments} from "../payment/common/modal/documentQueryHelper";

/*
 * Display the records in this trade data table
 */
class TradeDataContentBlock extends Component {
    constructor(props) {
        super(props);
        let pageSize = PAGE_SIZE;
        if (localStorage.getItem("pageSize") !== null) {
            pageSize = parseInt(localStorage.getItem("pageSize"));
        }
        this.state = {
            filteringText: '',
            filteringTokens: [],
            filteringOptions: [],
            pageSize: pageSize,
            contentSelectorOptions: this.props.contentSelectorOptions,
            wrapLines: false,
            user: localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID),
            currencyPairDisabled: this.props.currencyPairDisabled,
            sortingDescending: true,
            sortingColumn: CONSTANT.TR_COL_USD_NOTIONAL
        }
        Logger.logInfo("Intialize QueryTradeData");
    }

    onWrapLinesChange({detail}) {
        this.setState({
            wrapLines: detail.value
        });
    }

    onSortingChange(evt) {
        Logger.logInfo("Sorting Event: " + evt.detail.sortingColumn + evt.detail.sortingDescending);
        this.setState({
            sortingColumn: evt.detail.sortingColumn,
            sortingDescending: evt.detail.sortingDescending
        });
    }

    onPropertyFilteringChange({detail}) {
        this.setState({
            filteringTokens: detail.tokens
        });
    }

    clearFilter() {
        this.setState({
            filteringText: '',
            filteringTokens: []
        });
    }

    onContentSelectionChange = (event) => {
        this.props.onContentSelectionChange(event.detail.contentSelection);
    }

    onPaginationChange = (event) => {
        localStorage.setItem("pageSize", event.detail.pageSize);
        this.setState({pageSize: event.detail.pageSize})
    }

    render() {
        Logger.logInfo("Child Render: " + this.state.sortingColumn + this.state.sortingDescending);
        Logger.logInfo("elementSelected: " + JSON.stringify(this.props.elementSelected));
        Logger.logInfo("this.state.newComment.length: " + this.props.newComment.length);
        Logger.logInfo("this.state.contentSelectorOptions: " + JSON.stringify(this.state.contentSelectorOptions));
        let checkoutDisabled = true;
        let updateDisabled = true;
        let checkinDisabled = true;
        let exportDisabled = true;
        let currencyPairDisabled = this.state.currencyPairDisabled;
        let rejectDisabled = true;
        let executeDisabled = true;
        let commentsDisabled = true;
        let viewDocumentButton = <></>;
        let singleUploadDisabled = true;

        //Check if atleast one Selected element is Checked OUt
        if (this.props.elementSelected.length > 0) {

            // logic for viewing documents
            const selectedIds = this.props.elementSelected.map(item => item[CONSTANT.PR_FX_COL_TRADE_ID]);
            let shouldDisplayViewDocumentButton = false;
            selectedIds.forEach(recordId => {
                if (hasDocumentAttached(recordId, this.props.recordIdToDocumentIdMapping)) {
                    shouldDisplayViewDocumentButton = true;
                }
            });
            viewDocumentButton = shouldDisplayViewDocumentButton ?
                <Button onClick={() =>this.props.handleQueryDocument()}>View Documents</Button> : "";

            //All items must be Approved
            checkoutDisabled = ((this.props.elementSelected.findIndex(
                (obj => obj[CONSTANT.TR_COL_STATUS] !== CONSTANT.RECORD_STATUS_APPROVED 
                    && obj[CONSTANT.TR_COL_STATUS] !== CONSTANT.RECORD_STATUS_ERROR1
                    && obj[CONSTANT.TR_COL_STATUS] !== CONSTANT.RECORD_STATUS_ERROR2)) === -1) ? false : true);

            //All items must be CheckedOut
            updateDisabled = ((this.props.elementSelected.findIndex(
                (obj => obj[CONSTANT.TR_COL_STATUS] !== CONSTANT.RECORD_STATUS_CHECKEDOUT)) === -1) ? false : true);

            if (this.props.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER) ||
                ((this.props.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
                this.props.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER)) && getStage() !== 'prod')) {
                exportDisabled = updateDisabled;
            } else {
                exportDisabled = true;
            }
            currencyPairDisabled = updateDisabled;
            rejectDisabled = updateDisabled;

            Logger.logInfo("render commentsList: " + this.props.commentsList.size);
            if (this.props.commentsList < 2 && !updateDisabled) {
                commentsDisabled = false;
            } else {
                commentsDisabled = true;
            }
            Logger.logInfo("render commentsDisabled: " + commentsDisabled);
        }

        //Comment window
        let modalComments =
            <Modal
                visible={this.props.commentVisible}
                header="Comments"
                onDismiss={this.props.handleDismissClick}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link"
                                onClick={() => this.props.handleDismissClick()}>Cancel</Button>
                            <Button variant="primary"
                                disabled={false}
                                onClick={() => this.props.handleAddCommentClick(this.props.elementSelected, this.props.newComment)}
                        >Add</Button>
                        </SpaceBetween>
                    </Box>
               }>
                         <span>
                            <h4>
                                Add comments below.
                            </h4>
                                  <Textarea name="newComment" value={this.props.newComment}
                                            onChange={this.props.handleCommentUpdate}></Textarea>
                                  <div>
                                      <Table
                                          columnDefinitions={USER_COMMENTS_DEFINITION}
                                          items={this.props.userComments['userComment']}
                                          header={<h2>Comments Log</h2>}
                                          stickyHeader={true}
                                          wrapLines={true}
                                          resizableColumns>
                                      </Table>
                                  </div>
                         </span>
            </Modal>;

        //Trade Export Summary Window
        let tradeExportSummary = 
            <TradeExportSummaryModal
                platform={this.props.platform}
                elementSelected={this.props.elementSelected}
                tradeSummaryVisible={this.props.tradeSummaryVisible}
                handleTradeSummaryDismissClick={this.props.handleTradeSummaryDismissClick}
                handleTradeSummaryExportClick={this.props.handleTradeSummaryExportClick}
            />;

        let checkoutErrorConfirmModal =
            <CheckoutErrorConfirmModal
                elementSelected={this.props.elementSelected}
                checkoutConfirmModalVisible={this.props.checkoutConfirmModalVisible}
                handleCheckoutConfirmDismissClick={this.props.handleCheckoutConfirmDismissClick}
                handleCheckoutConfirmClick={this.props.handleCheckoutConfirmClick}
            />;

        //Check if all the Selected items are Export status
        if (this.props.elementSelected.length > 0) {
            checkinDisabled = (
                (this.props.elementSelected.findIndex((obj => obj[CONSTANT.TR_COL_STATUS] === CONSTANT.RECORD_STATUS_APPROVED)) > -1) ||
                (this.props.elementSelected.findIndex((obj => obj[CONSTANT.TR_COL_STATUS] === CONSTANT.RECORD_STATUS_REJECTED_TRADE)) > -1) ||
                (this.props.elementSelected.findIndex((obj => obj[CONSTANT.TR_COL_STATUS] === CONSTANT.RECORD_STATUS_ERROR1)) > -1) ||
                (this.props.elementSelected.findIndex((obj => obj[CONSTANT.TR_COL_STATUS] === CONSTANT.RECORD_STATUS_ERROR2)) > -1)
                ? true : false);
        }

        //Check if all the Selected items are Export status
        if (this.props.elementSelected.length > 0) {
            executeDisabled = ((this.props.elementSelected.findIndex(
                (obj => obj[CONSTANT.TR_COL_STATUS] !== CONSTANT.RECORD_STATUS_EXPORTED)) === -1) ? false : true);
        }

        Logger.logInfo("render currencyPairDisabled:" + currencyPairDisabled + new Date());
        let header = <></>;
        let actions = "";
        //Set Header based on UserType, following groups gets all Actions to work on Trade:
        // Reviewers, Prod Admins, Developers in Non-Prod Env
        if (this.props.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER) ||
            this.props.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.props.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) &&
                    getStage() !== 'prod')) {
            actions =
                    <Box>
                        <SpaceBetween direction="horizontal" size="xs">
                        <FutureDateElement title={'Value Date'}
                                           name={'valueDate'}
                                           value={this.props.valueDate}
                                           disabled={false}
                                           required={false}
                                           handleChange={this.props.handleValueChange}
                                           setErrorFlag={null}/>
                        <DropDownElement title={'Currency Pair'}
                                         name={'currencyPair'}
                                         value={this.props.currencyPair}
                                         options={this.props.currencyPairList}
                                         disabled={currencyPairDisabled}
                                         required={true}
                                         handleChange={this.props.handleValueChange}
                                         setErrorFlag={null}/>
                        <Button name="CheckOut"
                                disabled={checkoutDisabled}
                                onClick={() => this.props.handleCheckoutClick(this.props.elementSelected)}>Check Out</Button>
                        <Button disabled={checkinDisabled}
                                onClick={() => this.props.handleUpdateClick(this.props.elementSelected, "Check In")}>Check In</Button>
                        <Button disabled={singleUploadDisabled}
                                onClick={() => this.props.handleSingleDocumentClick()}>Single Upload Document</Button>
                        <Button disabled={rejectDisabled}
                                onClick={() => this.props.handleUpdateClick(this.props.elementSelected, "Reject")}>Reject</Button>
                        <Button disabled={updateDisabled}
                                onClick={() => this.props.handleUpdateClick(this.props.elementSelected, "Update")}>Update</Button>
                        <Button name="Export360T"
                                disabled={exportDisabled}
                                onClick={() => this.props.handleExportClick(this.props.elementSelected, "360T")}>Export 360T</Button>
                        <Button name="ExportBloomberg"
                                disabled={exportDisabled}
                                onClick={() => this.props.handleExportClick(this.props.elementSelected, "Bloomberg")}>Export Bloomberg</Button>
                        <ButtonDropdown 
                                items={MANUAL_EXPORT_DROPDOWN_ITEMS}
                                onItemClick={(event) => this.props.handleManualExportClick(this.props.elementSelected, event.detail.id)}
                                disabled={exportDisabled}>
                                Manual Export
                        </ButtonDropdown>
                        <Button name="Comments"
                                disabled={commentsDisabled}
                                onClick={() => this.props.handleViewCommentClick()}>Add/View Comments</Button>
                        <Button name="Execute"
                                disabled={executeDisabled}
                                onClick={() => this.props.handleUpdateClick(this.props.elementSelected, "Execute")}> Execute </Button>
                        {viewDocumentButton}
                        </SpaceBetween>                    
                    </Box>;
            header = <SpaceBetween direction='vertical' size="m">
                <>{this.props.feedbackMessage}</>
                <>{actions}</>
            </SpaceBetween>;
        } else if (this.props.userGroup.includes(CONSTANT.USER_GROUP_MO)) {
            // the MO LDAP group does not have an access to any buttons besides viewing all types of trades
        } else {
            actions = <Button name="Comments"
                                disabled={false}
                                onClick={() => this.props.handleViewCommentClick()}> Add/View Comments </Button>;
            header = <SpaceBetween direction='vertical' size="m">
                <>{this.props.feedbackMessage}</>
                <>{actions}</>
            </SpaceBetween>;
        }

        // Defines the default sorting state of the Clouscape table. The table will be sorted
        // by USD Notional in descending order by default.
        const defaultSortingState = {
            defaultState: {
                sortingColumn: {
                    sortingField: CONSTANT.TR_COL_USD_NOTIONAL
                },
                isDescending: true
            }
        }

        Logger.logInfo("render start:");
        return (
                <Container>
                    <ColumnLayout columns={1}>
                        <div>
                            {modalComments}
                        </div>
                        <DataContentTable
                            wrapLines={false}
                            stickyHeader={true}
                            columnDefinitions={this.props.columnDefinitions}
                            onRowClick={this.props.onRowClick}
                            defaultSortingState={defaultSortingState}
                            distributions={this.props.recordIdToDocumentIdMapping ? this.props.distributions : []}
                            header={header}
                            filterOptions={this.props.filterOptions}
                            trackBy="System ID"
                            elementSelected={this.props.elementSelected}
                            onSelectionChange={this.props.onSelectionChange.bind(this)}
                            pageSelectorOptions={PAGE_SELECTOR_OPTIONS}
                            contentSelectorOptions={this.state.contentSelectorOptions}
                            isItemDisabled={item => isItemDisabled(item, this.state.user)}
                        />
                        <div>
                            {tradeExportSummary}
                        </div>
                        <div>
                            {checkoutErrorConfirmModal}
                        </div>
                    </ColumnLayout>
                </Container>
            );
    }
}

/*
 *  Summary Modal for trade export
 */
class TradeExportSummaryModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let footer = <Box float='right'>
            <SpaceBetween size="xs" direction="horizontal">
                <Button variant="link"
                    onClick={() => this.props.handleTradeSummaryDismissClick()}> Cancel </Button>
                 <Button variant="primary"
                    disabled={false}
                    onClick={() => this.props.handleTradeSummaryExportClick(this.props.platform, this.props.elementSelected)}
        > Export </Button>
            </SpaceBetween>
        
        </Box>;
        return (
            <Modal
                visible={this.props.tradeSummaryVisible}
                header="Trade Export Summary"
                expandToFit={true}
                onDismiss={this.props.handleTradeSummaryDismissClick}
                footer={footer}>

        <h4>You are about to export {this.props.elementSelected.length} trades to {this.props.platform} system</h4>
            </Modal>
            );
    }
}

/*
*   Checkout Modal Confirmation
*/
class CheckoutErrorConfirmModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let footer = <Box float='right'>
            <SpaceBetween size="xs" direction="horizontal">
                <Button variant="link"
                    onClick={() => this.props.handleCheckoutConfirmDismissClick()}> Cancel </Button>
                <Button variant="primary"
                    disabled={false}
                    onClick={() => this.props.handleCheckoutConfirmClick(this.props.elementSelected)}
        > Confirm </Button>
            </SpaceBetween>
         </Box>;
        return (
            <Modal
                visible={this.props.checkoutConfirmModalVisible}
                header="Warning"
                expandToFit={true}
                onDismiss={this.props.handleCheckoutConfirmDismissClick}
                footer={footer}>

        <h4>By checking out the trade you are confirming that this trade has already cleared the previous error</h4>
            </Modal>
            );
    }
}

/*
 *  Query Trade Parent element that wraps all the above component
 *  Fetches the Trade Data available for Review.
 */
class QueryTradeData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: "",
            loading: false,
            distributions: [],
            elementSelected: [],
            recordIdToDocumentIdMapping: {},
            recordIdToDocumentIdMappingLoaded: false,
            // This needs to be passed in as props, Authentication may return one of these values:
            // 'TTECH-TRADE-REVIEWER-ACCESS', 'TTECH-TRADE-INTERCOMPANY-ACCESS','TTECH-TRADE-PROD-ADMIN-ACCESS',
            // 'trs-preprod-admin-access', 'TSYMiddleOffice'
            userGroup: localStorage.getItem(CONSTANT.USER_GROUP_NAME),
            user: localStorage.getItem(CONSTANT.LOCAL_STORAGE_USERID),
            valueDate: "",
            currencyPair: "",
            commentVisible: false,
            currencyPairList: [],
            currencyPairDisabled:false,
            newComment: '',
            commentsList: 0,
            userComments: {'userComment': [], 'commentId': ''},
            tradeSummaryVisible: false,
            checkoutConfirmModalVisible: false,
            platform: '',
            documentData:[],
            documentVisible: false,
            singleUploadVisible: false,
        }
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCommentUpdate = this.handleCommentUpdate.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
    }

    componentDidMount() {
        Logger.logInfo('componentDidMount');
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        })
        var parameter = {};

        // Based on the UserType pass the parameters, not passing userGroup in param will retrieve all types of trades.
        // Passing specific userGroup in param will filter out certain types of trades based on backend configuration.
        // Following groups get to review all types of trades:
        // Reviewers, Prod Admins, Developers in Non-Prod Env
        if (this.state.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER) ||
            this.state.userGroup.includes(CONSTANT.USER_GROUP_MO) ||
            this.state.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.state.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) &&
                getStage() !== 'prod')) {
            parameter = {indexString: CONSTANT.INDEX_STRING_STATUSREVIEW}
        } else {
            parameter = {indexString: CONSTANT.INDEX_STRING_STATUSREVIEW, userGroup: this.state.userGroup}
        }

        APIClient.invoke('GET', 'trade', parameter,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    Logger.logInfo("Data reterived: " + JSON.stringify(data));
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            Logger.logInfo(JSON.stringify(data));

                            this.setState({
                                status: CONSTANT.MODAL_UPDATE_SUCCESS,
                                distributions: data.data
                            });
                            Logger.logInfo("After distributions:" + JSON.stringify(this.state.distributions))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Response Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            Logger.logError("System Error when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_SYSTEM_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        case CONSTANT.MODAL_REQUEST_ERROR:
                            this.setState({
                                status: CONSTANT.MODAL_REQUEST_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other Error when  Querying data records ");
                    this.setState({
                        status: CONSTANT.MODAL_OTHER_ERROR,
                        statusMsg: "Other System Error when Querying trade data records"
                    });
                }
            });

        //Query RecordIdToDocumentIdMapping
        const paramGetRecordIdToDocumentIdMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_DOCUMENT_ID_MAPPING};

        APIClient.invoke('GET', '/attachDocuments/documentData', paramGetRecordIdToDocumentIdMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    this.setState({
                        recordIdToDocumentIdMapping: data,
                        recordIdToDocumentIdMappingLoaded: true,
                    });
                    Logger.logInfo("recordIdToDocumentIdMaping state:" + JSON.stringify(this.state.recordIdToDocumentIdMapping))
                } else {
                    Logger.logError("Other Error when Querying recordIdToDocumentIdMapping information");
                    if (this.state.status !== CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying recordIdToDocumentIdMapping information"
                        });
                    }
                }
            });
    }

    // queryDocuments for the queryDocumentData page.
    handleQueryDocument = async () => {
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        });

        const selectedIds = this.state.elementSelected.map(item => item[CONSTANT.PR_FX_COL_TRADE_ID])
        const result = await queryDocuments(selectedIds);

        switch (result.status) {
            case CONSTANT.RESPONSE_SUCCESS:
                this.setState({
                    documentData: result.documentData,
                    documentVisible: true,
                });
                Logger.logInfo("Query Document Data succeeded.");
                break;
            case CONSTANT.RESPONSE_ERROR:
                Logger.logError("Error Query Document Data = " + this.state.elementSelected.toString());
                this.setState({
                    documentVisible: false,
                    status: CONSTANT.MODAL_QUERY_ERROR
                });
                break;
            case CONSTANT.RESPONSE_SYSTEM_ERROR:
                Logger.logError("System Error when Query Document Data = " + this.state.elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_SYSTEM_ERROR,
                    statusMsg: JSON.stringify(result.errorMessage)
                });
                break;
            case CONSTANT.MODAL_OTHER_ERROR:
                Logger.logError("Other System Error when Query Document Data");
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when query document data records."
                });
                break;
            default:
                Logger.logError("Unhandled response status:", result.status);
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Received unknown response status"
                });

        }
    }

    handleDismissSingleUpload = () => {
        this.setState({
            singleUploadVisible: false,
        })
    }
    handleSingleDocumentClick = () => {
        this.setState({
            singleUploadVisible: true
        })
    }


    //Handle Value Change
    handleValueChange = (name, value) => {
        Logger.logInfo("handleValueChange: " + name + " " + value);
        this.setState({[name]: value});
    };

    onSelectionChange({detail}) {
        Logger.logInfo("onSelectionChange: " + JSON.stringify(detail));
        this.setState(() => {
            Logger.logInfo("Start: ");
            let currencyPairList = [];
            let commentsList = [];
            let commentObject = {};
            let optionList = [];

            //loop through selected elements to identify unique CommentIds to enable disable Add Comment button
            detail.selectedItems.forEach((item) => {
                Logger.logInfo("item[CONSTANT.TR_COL_COMMENT_ID: " + item[CONSTANT.TR_COL_COMMENT_ID]);
                if (item[CONSTANT.TR_COL_COMMENT_ID] != null) {
                    commentObject["commentId"] = item[CONSTANT.TR_COL_COMMENT_ID];
                    commentObject["userComment"] = item[CONSTANT.TR_COL_USER_COMMENTS];
                    commentsList.push(item[CONSTANT.TR_COL_COMMENT_ID]);
                }
                currencyPairList.push(item['Currency Pair']);
            });

            let reverseArrayList = Array.from(new Set(currencyPairList)).map((item) => {
                return item.substr(3, 3) + item.substr(0, 3)
            });

            Logger.logInfo("reverseArrayList: " + reverseArrayList);

            let completeArrayList = Array.from(new Set((currencyPairList).concat(reverseArrayList)));

            //populate Currency List only if the selection has same Currency
            if ( completeArrayList.length < 3) {
                optionList = completeArrayList.map(item => (({
                    label: item,
                    id: item,
                    value: item
                })));
            }
            Logger.logInfo("optionList: " + typeof (optionList));

            Logger.logInfo("local commentObject: " + JSON.stringify(commentObject));
            Logger.logInfo("local commentsList: " + JSON.stringify(commentsList));
            let commentsArray = Array.from(new Set(commentsList));
            Logger.logInfo("local commentsArray: " + JSON.stringify(commentsArray));

            if (commentsArray.length === 1) {
                return {
                    currencyPairList: optionList,
                    newComment: '',
                    currencyPair: '',
                    commentsList: 1,
                    userComments: commentObject,
                    elementSelected: detail.selectedItems
                };
            } else {
                return {
                    currencyPairList: optionList,
                    newComment: '',
                    currencyPair: '',
                    commentsList: commentsArray.length,
                    userComments: {'userComment': [], 'commentId': ''},
                    elementSelected: detail.selectedItems
                };
            }
        });
        Logger.logInfo("State currencyPairList: " + this.state.currencyPairList);
        Logger.logInfo("State commentsList: " + JSON.stringify(this.state.commentsList));
    }

    /**
     * Function for row click in the data table.
     * The elementSelected is the row selected.
     */
    onRowClick = ({detail}) => {
        if (!(detail.item[CONSTANT.TR_COL_CHECKEDOUTUSER] !== this.state.user && detail.item[CONSTANT.TR_COL_CHECKEDOUTUSER] !== undefined
            && detail.item[CONSTANT.TR_COL_STATUS] !== 'Approved')) {
            let newElementSelected = [...this.state.elementSelected];
            if (newElementSelected.includes(detail.item)) {
                newElementSelected = newElementSelected.filter(x => x !== detail.item)
            } else {
                newElementSelected.push(detail.item)
            }
            this.onSelectionChange({detail: {selectedItems: newElementSelected}});
        }
    };

    onContentSelectionChange = (selection) => {
        let hidden_option_open_trade = [];
        hidden_option_open_trade = VISIBLE_COLUMN_OPTIONS_OPEN_TRADE.filter(x => selection.indexOf(x) === -1);
        Logger.logInfo(hidden_option_open_trade);
        localStorage.setItem("USER_HIDDEN_OPTION_OPEN_TRADE", JSON.stringify(hidden_option_open_trade));
    }

    //Handle Comment Update
    handleCommentUpdate = (event) => {
        Logger.logInfo("Comment event: " + JSON.stringify(event));
        this.setState({
            newComment: event.detail.value
        });

    }

    //handle view Comment Click
    handleViewCommentClick = () => {
        Logger.logInfo("handleViewCommentClick: ");
        this.setState({commentVisible: true})
        Logger.logInfo("commentVisible: " + this.state.commentVisible);
    }

    //Handle Dismiss or Cancel for Comment window
    handleDismissClick = () => {
        Logger.logInfo("handleDismissClick Start: ");
        if (this.state.commentVisible) {
            this.setState({commentVisible: false})
        }
    }

    handleDismissDocumentModalClick = () => {
        this.setState({
            documentVisible: false,
        })
    }

    handleTradeSummaryDismissClick = () => {
        if (this.state.tradeSummaryVisible) {
            this.setState({tradeSummaryVisible: false});
        }
    }

    handleCheckoutConfirmDismissClick = () => {
        if (this.state.checkoutConfirmModalVisible) {
            this.setState({checkoutConfirmModalVisible: false});
        }
    }

    handleCheckoutConfirmClick = (elementSelected) => {
        this.setState({
            checkoutConfirmModalVisible: false
        })
        this.startCheckout(elementSelected);
    }

    handleCheckoutClick = (elementSelected) => {
        let checkoutConfirmModalVisible = (elementSelected.findIndex((obj => obj[CONSTANT.TR_COL_STATUS] === CONSTANT.RECORD_STATUS_ERROR1)) > -1) ||
        (elementSelected.findIndex((obj => obj[CONSTANT.TR_COL_STATUS] === CONSTANT.RECORD_STATUS_ERROR2)) > -1)

        if (checkoutConfirmModalVisible) {
            this.setState({checkoutConfirmModalVisible: true});
        } else {
            this.startCheckout(elementSelected);
        }
    }

    handleTradeSummaryExportClick = (platformName, elementSelected) => {
        var parameter = {};
        // Based on the UserType pass the parameters
        if (this.state.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER) ||
            this.state.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.state.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) && getStage() !== 'prod')) {
            parameter = {
                indexString: CONSTANT.INDEX_STRING_STATUSREVIEW,
                platform: platformName
            }
        } else {
            parameter = {
                indexString: CONSTANT.INDEX_STRING_STATUSREVIEW,
                userGroup: this.state.userGroup,
                platform: platformName
            }
        }

        Logger.logInfo("----------start export--------");
        Logger.logInfo(elementSelected);
        var exportData = Object.assign([], elementSelected);
        Logger.logInfo(exportData);
        this.setState({
            status: CONSTANT.FEEDBACK_EXPORTING,
            tradeSummaryVisible: false
        });
        APIClient.invoke('POST', 'exporttrade', parameter, exportData, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        this.setState({
                            status: CONSTANT.FEEDBACK_EXPORT_SUCCESS,
                            elementSelected: [],
                            distributions: requestData['exportResult']
                        });
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error exporting data records with tradeId id list = " + elementSelected.toString()
                        + ", Please refresh page and retry");
                        this.setState({
                            status: CONSTANT.FEEDBACK_EXPORT_ERROR,
                            distributions: requestData['exportResult'],
                            statusMsg: requestData['errorMessage']
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("Our system encounters errors, please reach out to the develop team");
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: "Our system encounters errors, please refresh the page and reach out to the develop team"
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when Exporting trade data records with");
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when Exporting trade"
                });
            }
        });

        Logger.logInfo("----------end export--------");
    }

    // set the Normal status
    recoverAddButtonEmptyState = () => {
        this.setState({
            status: CONSTANT.MODAL_EMPTY
        });
    };

    handleAddCommentClick = (elementSelected, newComment) => {
        Logger.logInfo("handleAddCommentClick: " + newComment);

        if (newComment) {
            Logger.logInfo("Updating Comment: ");
            //Generate a new comment id based on date
            let commentId = Date.now().toString();
            let commentDate = new Date().toDateString();

            //Add the New Comments selected elements
            elementSelected.map((entry, index) => {

                let commentData = elementSelected[index][CONSTANT.TR_COL_USER_COMMENTS];
                //Populate the Export attributes, == checks for Null as well as undefined
                if (elementSelected[index][CONSTANT.TR_COL_COMMENT_ID] == null) {
                    elementSelected[index][CONSTANT.TR_COL_COMMENT_ID] = commentId;
                }

                let commentObject = {};
                commentObject["comment"] = newComment;
                commentObject["user"] = this.state.user;
                commentObject["commentDate"] = commentDate;

                commentData.push(commentObject);
                Logger.logInfo("commentData: " + JSON.stringify(commentData));

                elementSelected[index][CONSTANT.TR_COL_USER_COMMENTS] = commentData;
                Logger.logInfo("elementSelected: " + JSON.stringify(elementSelected));
            })

            Logger.logInfo("handleUpdate: ");
            //Update the Comment attributes
            this.handleUpdate(elementSelected, 'AddComments');

            //Disable Comments Modal windows
            this.setState({commentVisible: false});
        } else {
            Logger.logInfo("No Comment: ");
            //Disable Comments Modal windows
            this.setState({commentVisible: true});
        }
        Logger.logInfo("handleAddCommentClick End: ");
    }

    //Handle Export Data into Excel
    handleExportClick = (elementSelected, name) => {
        Logger.logInfo("handleExportClick name: " + name + " elementSelected: " + JSON.stringify(elementSelected));
        let BreakException = {};
        let errorStatus = '';

        try {
            //Perform Validation
            elementSelected.forEach((entry, index) => {
                //Check the ValueDate is not in Past
                if ((new Date(elementSelected[index][CONSTANT.TR_COL_VALUEDATE])) < FormatData.formatNewCurrDate()) {
                    errorStatus = CONSTANT.MODAL_VALUEDATE_ERROR;
                    throw BreakException;
                }
            })

            if (name === "360T") {
                // 360T file
                this.setState({tradeSummaryVisible: true, platform: name});
            } else if (name === "Bloomberg") {
                //Perform Validation
                elementSelected.forEach((entry, index) => {
                    //Check for Account is present/undefined
                    if (elementSelected[index][CONSTANT.TR_COL_BL_ACCOUNT] == null) {
                        errorStatus = CONSTANT.MODAL_ACCOUNT_ERROR;
                        throw BreakException;
                    }
                })

                this.setState({tradeSummaryVisible: true, platform: name});
            }

        } catch (e) {
            Logger.logInfo("Export Error: ");
            this.setState({
                status: errorStatus
            });
        }
    };

    //Handle Export Data into Excel
    handleManualExportClick = (elementSelected, name) => {
        Logger.logInfo("handle manual name: " + name + " elementSelected: " + JSON.stringify(elementSelected));
        let fileName = '';
        let delimiter = '';
        let header = '';
        let exportElement = '';
        let bookType = '';
        let BreakException = {};
        let errorStatus = '';

        try {
            //Perform Validation
            elementSelected.forEach((entry, index) => {
                //Check the ValueDate is not in Past
                if ((new Date(elementSelected[index][CONSTANT.TR_COL_VALUEDATE])) < FormatData.formatNewCurrDate()) {
                    errorStatus = CONSTANT.MODAL_VALUEDATE_ERROR;
                    throw BreakException;
                }
            })

            // 360T file
            if (name === "download_360t") {

                //set 360T parameters
                fileName = "TRS_360_File" + FormatData.formatNewFileDate() + ".csv";
                delimiter = EXPORT_FILE_360T_DELIMITER;
                header = EXPORT_FILE_360T_HEADER;
                bookType = EXPORT_FILE_360T_BOOKTYPE;

                elementSelected.forEach((entry, index) => {

                    //Determine CF_ACTION
                    if (elementSelected[index][CONSTANT.TR_COL_CF_CURRENCY1] === elementSelected[index][CONSTANT.TR_COL_CF_NOTIONALCURRENCY]) {
                        elementSelected[index][CONSTANT.TR_COL_CF_ACTION] = elementSelected[index][CONSTANT.TR_COL_BUY_SELL];
                    } else {
                        //Flip the Side
                        if (elementSelected[index][CONSTANT.TR_COL_BUY_SELL] === CONSTANT.SIDE_BUY) {
                            elementSelected[index][CONSTANT.TR_COL_CF_ACTION] = CONSTANT.SIDE_SELL;
                        } else {
                            elementSelected[index][CONSTANT.TR_COL_CF_ACTION] = CONSTANT.SIDE_BUY;
                        }
                    }

                    //Populate the Export attributes
                    elementSelected[index][CONSTANT.TR_COL_STATUS] = CONSTANT.RECORD_STATUS_EXPORTED;
                    elementSelected[index][CONSTANT.TR_COL_TRADE_SYSTEM] = CONSTANT.TRADE_SYSTEM_360T;
                    elementSelected[index][CONSTANT.TR_COL_EXPORTDATE] = FormatData.formatNewDate();
                    elementSelected[index][CONSTANT.TR_COL_CFEFFECTIVEDATE] = FormatData.formatEURDate(elementSelected[index][CONSTANT.TR_COL_VALUEDATE]);
                    elementSelected[index][CONSTANT.TR_COL_EXPORTEDBY] = this.state.user;
                    elementSelected[index][CONSTANT.TR_COL_CFNOTIONALAMOUNT1] = FormatData.formatAmount(elementSelected[index][CONSTANT.TR_COL_NOTIONAL]);
                    elementSelected[index][CONSTANT.TR_COL_CFPRODUCT] = CONSTANT.EXPORT_TR_COL_360_CFPRODUCT;
                    elementSelected[index][CONSTANT.TR_COL_CFLEGALENTITY] = CONSTANT.EXPORT_TR_COL_CFLEGALENTITY;
                    elementSelected[index][CONSTANT.TR_COL_CF_EDITABLE] = CONSTANT.EXPORT_TR_COL_CFLEDITABLE;
                    elementSelected[index][CONSTANT.TR_COL_EXPORT_FILE] = fileName;
                })

                //Create 360T format
                exportElement = FormatData.format360T(elementSelected);

                //For Export the data into CSV after successful update
                Logger.logInfo("Export Data: " + JSON.stringify(exportElement));
                xlsxParser.writeFileANSI(fileName, delimiter, exportElement, header);
            }

            //Bloomberg File
            else if (name === "download_bloomberg" || name === "download_manual") {
                //set Bloomberg parameters
                if (name === "download_bloomberg") {
                    fileName = "TRS_Bloomberg_File" + FormatData.formatNewFileDate() + ".xls";
                } else {
                    fileName = "TRS_Manual_File" + FormatData.formatNewFileDate() + ".xls";
                }
                
                delimiter = EXPORT_FILE_BLOOMBERG_DELIMITER;
                header = EXPORT_FILE_BLOOMBERG_HEADER;
                bookType = EXPORT_FILE_BLOOMBERG_BOOKTYPE;

                //Perform Validation
                elementSelected.forEach((entry, index) => {
                    //Check for Account is present/undefined
                    if (elementSelected[index][CONSTANT.TR_COL_BL_ACCOUNT] == null) {
                        errorStatus = CONSTANT.MODAL_ACCOUNT_ERROR;
                        throw BreakException;
                    }
                })

                elementSelected.map((entry, index) => {

                    //Populate the Export attributes
                    elementSelected[index][CONSTANT.TR_COL_STATUS] = CONSTANT.RECORD_STATUS_EXPORTED;
                    elementSelected[index][CONSTANT.TR_COL_TRADE_SYSTEM] = name === "download_bloomberg" 
                                                                    ? CONSTANT.TRADE_SYSTEM_BLOOMBERG
                                                                    : CONSTANT.TRADE_SYSTEM_MANUAL;
                    elementSelected[index][CONSTANT.TR_COL_EXPORTDATE] = FormatData.formatNewDate();
                    elementSelected[index][CONSTANT.TR_COL_EXPORTEDBY] = this.state.user;
                    elementSelected[index][CONSTANT.TR_COL_BL_INSTRUMENT] = CONSTANT.EXPORT_TR_COL_BL_CFPRODUCT;
                    elementSelected[index][CONSTANT.TR_COL_BL_CURRENCYPAIR] = elementSelected[index][CONSTANT.TR_COL_CURRENCYPAIR];
                    elementSelected[index][CONSTANT.TR_COL_BL_SIDE] = (elementSelected[index][CONSTANT.TR_COL_BUY_SELL]).substr(0, 1);
                    elementSelected[index][CONSTANT.TR_COL_BL_TT] = elementSelected[index][CONSTANT.TR_COL_CF_AMZNTT];
                    elementSelected[index][CONSTANT.TR_COL_BL_AMZNTRADEID] = elementSelected[index][CONSTANT.TR_COL_CF_TRADEID];
                    elementSelected[index][CONSTANT.TR_COL_BL_SOURCEACCOUNT] = elementSelected[index][CONSTANT.TR_COL_CF_SOURCEACCOUNT];
                    elementSelected[index][CONSTANT.TR_COL_BL_CURRENCY] = elementSelected[index][CONSTANT.TR_COL_CF_NOTIONALCURRENCY];
                    elementSelected[index][CONSTANT.TR_COL_BL_AMOUNT] = elementSelected[index][CONSTANT.TR_COL_NOTIONAL];
                    elementSelected[index][CONSTANT.TR_COL_BL_SSIKEYWORD] = elementSelected[index][CONSTANT.TR_COL_CF_MISYS_Beneficiary];
                    elementSelected[index][CONSTANT.TR_COL_BL_TRADECODE] = elementSelected[index][CONSTANT.TR_COL_CF_TRADECODE];
                    elementSelected[index][CONSTANT.TR_COL_EXPORT_FILE] = fileName;
                    elementSelected[index][CONSTANT.TR_COL_BL_MARKETTYPE] = CONSTANT.EXPORT_TR_COL_MARKETTYPE;
                })

                //Create Bloomberg format
                exportElement = FormatData.formatBloomberg(elementSelected);

                //For Export the data into CSV after successful update
                Logger.logInfo("Export Data: " + JSON.stringify(exportElement));
                xlsxParser.writeFile(fileName, delimiter, exportElement, header, bookType);
            }

            Logger.logInfo("handleUpdate: ");
            //Update the Export related attributes
            this.handleUpdate(elementSelected, 'Export');
            Logger.logInfo("handleUpdate End: ");

        } catch (e) {
            Logger.logInfo("Export Error: ");
            this.setState({
                status: errorStatus
            });
        }
    };

    //Handle Checkout after clarifying the potential error
    startCheckout = (elementSelected) => {
        Logger.logInfo("handleCheckoutClick elementSelected: " + JSON.stringify(elementSelected));

        //Check if the Selected Elements have Checkout Status already
        let errorData = elementSelected.findIndex((obj => obj[CONSTANT.TR_COL_STATUS] === CONSTANT.RECORD_STATUS_CHECKEDOUT));
        Logger.logInfo("errorData: " + errorData);

        if (errorData !== -1) {
            Logger.logInfo("Set error Status: " + errorData);
            this.setState({status: CONSTANT.MODAL_CHECKEDOUT_RECORDS})
            Logger.logInfo("Status: " + this.state.status);
        } else {

            this.setState({
                status: CONSTANT.MODAL_SUBMITTING
            });

            //Update the Checkout Status
            elementSelected.map((entry, index) => {
                //find the Index of array
                elementSelected[index][CONSTANT.TR_COL_STATUS] = CONSTANT.RECORD_STATUS_CHECKEDOUT;
                elementSelected[index][CONSTANT.TR_COL_CHECKEDOUTUSER] = this.state.user;
                elementSelected[index][CONSTANT.TR_COL_CHECKEDOUTDATE] = FormatData.formatNewDate();
                elementSelected[index][CONSTANT.TR_COL_TRADE_SYSTEM] = "";
                elementSelected[index][CONSTANT.TR_COL_EXPORTDATE] = "";

            })
            var parameter = {};
            // Based on the UserType pass the parameters
            if (this.state.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER) ||
                this.state.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
                (this.state.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) && getStage() !== 'prod')) {
                parameter = {indexString: CONSTANT.INDEX_STRING_STATUSREVIEW, request: "CheckOutTrade"}
            } else {
                parameter = {
                    indexString: CONSTANT.INDEX_STRING_STATUSREVIEW,
                    userGroup: this.state.userGroup,
                    request: "CheckOutTrade"
                }
            }

            APIClient.invoke('POST', 'trade', parameter, elementSelected, (err, requestData) => {
                Logger.logInfo("Response data: " + JSON.stringify(requestData));
                if (!err) {
                    switch (requestData.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            this.setState({
                                status: CONSTANT.MODAL_UPDATE_SUCCESS,
                                distributions: requestData.data,
                                elementSelected: []
                            });
                            // Logger.logInfo("Success distributions: " + JSON.stringify(this.state.distributions));
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Response Error when Checking Out data records with tradeId id list = " + elementSelected.toString());
                            this.setState({
                                status: CONSTANT.MODAL_CHECKEDOUT_ERROR,
                                distributions: requestData.data,
                                statusMsg: requestData['errorData']
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(requestData));
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            Logger.logError("System Error when Checking Out data records with tradeId id list = " + elementSelected.toString());
                            this.setState({
                                status: CONSTANT.MODAL_SYSTEM_ERROR,
                                statusMsg: JSON.stringify(requestData['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(requestData));
                            break;
                        case CONSTANT.MODAL_REQUEST_ERROR:
                            this.setState({status: CONSTANT.MODAL_REQUEST_ERROR, distributions: requestData.data});
                            Logger.logInfo("Data error: " + JSON.stringify(requestData));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Other System Error when Checking Out trade data records with tradeId = " + elementSelected.toString());
                    this.setState({
                        status: CONSTANT.MODAL_OTHER_ERROR,
                        statusMsg: "Other System Error when Checking Out trade data records"
                    });
                }
            })
        }
    };

    //Handle Updates
    handleUpdate = (elementSelected, name) => {
        Logger.logInfo("handleUpdate elementSelected: " + JSON.stringify(elementSelected)
            + " name: " + name);

        this.setState({
            status: CONSTANT.MODAL_SUBMITTING
        });

        let newData = this.state.distributions;

        //Update the Selected Element Values
        elementSelected.map((entry, index) => {
            //find the Index of array
            var objIndex = newData.findIndex((obj => obj[CONSTANT.TR_COL_SYSTEMID] === entry[CONSTANT.TR_COL_SYSTEMID]));

            //For CheckIn update status back to Approved
            if (name === "Check In") {
                elementSelected[index][CONSTANT.TR_COL_STATUS] = CONSTANT.RECORD_STATUS_APPROVED;
                elementSelected[index][CONSTANT.TR_COL_CHECKEDOUTUSER] = "";
                elementSelected[index][CONSTANT.TR_COL_CHECKEDOUTDATE] = "";
                elementSelected[index][CONSTANT.TR_COL_TRADE_SYSTEM] = "";
                elementSelected[index][CONSTANT.TR_COL_EXPORTDATE] = "";
                elementSelected[index][CONSTANT.TR_COL_EXPORT_FILE] = "";
            }
            //For Update set the ValueDate and CurrencyPair
            else if (name === "Update") {
                if (this.state.valueDate !== "") {
                    elementSelected[index][CONSTANT.TR_COL_VALUEDATE] = this.state.valueDate;
                }

                if (this.state.currencyPair !== "") {
                    elementSelected[index][CONSTANT.TR_COL_CURRENCYPAIR] = this.state.currencyPair;
                    elementSelected[index][CONSTANT.TR_COL_CF_CURRENCY1] = this.state.currencyPair.substring(0,3);
                    elementSelected[index][CONSTANT.TR_COL_CF_CURRENCY2] = this.state.currencyPair.substring(3,6);
                }
            }
            //For Reject trade set the status as Rejected for Trade
            else if (name === "Reject") {
                elementSelected[index][CONSTANT.TR_COL_STATUS] = CONSTANT.RECORD_STATUS_REJECTED_TRADE;
                elementSelected[index][CONSTANT.TR_COL_REJECTION_DATE] = FormatData.formatNewDate();
            }
            //For Executed trade set the status as Executed
            else if (name === "Execute") {
                elementSelected[index][CONSTANT.TR_COL_STATUS] = CONSTANT.RECORD_STATUS_EXECUTED;
                elementSelected[index][CONSTANT.TR_COL_EXECUTION_DATE] = FormatData.formatNewDate();
            }
            //Capture WHO Columns for Audit
            elementSelected[index][CONSTANT.TR_COL_UPDATE_DATE] = FormatData.formatNewDate();
            elementSelected[index][CONSTANT.TR_COL_UPDATED_BY] = this.state.user;

            newData[objIndex] = entry;
        })
        Logger.logInfo("newData: " + JSON.stringify(newData));

        //Set parameters for Review
        var parameter = {};

        // Based on the UserType pass the parameters
        if (this.state.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER) ||
            this.state.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.state.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) && getStage() !== 'prod')) {
            parameter = {
                indexString: CONSTANT.INDEX_STRING_STATUSREVIEW,
                request: name
            }
        } else {
            parameter = {
                indexString: CONSTANT.INDEX_STRING_STATUSREVIEW,
                userGroup: this.state.userGroup,
                request: name
            }
        }

        Logger.logInfo("elementSelected: " + JSON.stringify(elementSelected));
        APIClient.invoke('POST', 'trade', parameter, elementSelected, (err, requestData) => {
            Logger.logInfo("Response data: " + JSON.stringify(requestData));
            if (!err) {
                switch (requestData.status) {
                    case CONSTANT.RESPONSE_SUCCESS:
                        //delete the corresponding record from UI table
                        this.setState({
                            status: CONSTANT.MODAL_UPDATE_SUCCESS,
                            distributions: requestData.data,
                            elementSelected: [],
                        });
                        Logger.logInfo("Success distributions: " + JSON.stringify(this.state.distributions));
                        break;
                    case CONSTANT.RESPONSE_ERROR:
                        Logger.logError("Error updating data records with tradeId id list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_UPDATE_ERROR,
                            distributions: requestData.data,
                            statusMsg: requestData['errorData']
                        });
                        break;
                    case CONSTANT.RESPONSE_SYSTEM_ERROR:
                        Logger.logError("System Error when updating data records with tradeId id list = " + elementSelected.toString());
                        this.setState({
                            status: CONSTANT.MODAL_SYSTEM_ERROR,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    case CONSTANT.MODAL_REQUEST_ERROR:
                        this.setState({
                            status: CONSTANT.MODAL_REQUEST_ERROR,
                            distributions: requestData.data,
                            statusMsg: JSON.stringify(requestData['errorData'])
                        });
                        break;
                    default:
                }
            } else {
                Logger.logError("Other System Error when updating trade data records with tradeId = " + elementSelected.toString());
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when updating trade data records"
                });
            }
        })
    };

    //render the Modal message
    getFeedbackMessage = () => {
        Logger.logInfo("Modal Message " + this.state.status);
        switch (this.state.status) {
            case CONSTANT.MODAL_QUERYING:
                return <PolarisV3FlashBar 
                    type="success"
                    loading={true}
                    content={<>Busy fetching data... Please wait.</>}
                    dismissible={false}
                    key={this.state.status}
                />;
            case CONSTANT.MODAL_VALUEDATE_ERROR:
                return (<Modal
                    visible={true}
                    header="Export failed"
                    onDismiss={this.recoverAddButtonEmptyState}
                > Items with Past Value Date! </Modal>);
            case CONSTANT.MODAL_ACCOUNT_ERROR:
                return (<Modal
                    visible={true}
                    header="Export failed"
                    onDismiss={this.recoverAddButtonEmptyState}
                > Account is mandatory for Bloomberg, Update in FOCUS! </Modal>);
            case CONSTANT.MODAL_OTHER_ERROR:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content={(<div>{this.state.statusMsg}</div>)}
                        dismissible={true}
                        header="Error"
                        key={this.state.status}
                    />);
            case CONSTANT.MODAL_CHECKEDOUT_ERROR:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content={(<div>Items failed to Check Out ! {FormatData.
                            formatFailedUpdatedBatchIntoBrowserMessage(this.state.statusMsg)}</div>)}
                        dismissible={true}
                        header="CheckOut failed"
                        key={this.state.status}
                    />);
            case CONSTANT.MODAL_UPDATE_ERROR:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content={(<div>{FormatData.
                            formatFailedUpdatedBatchIntoBrowserMessage(this.state.statusMsg)}</div>)}
                        dismissible={true}
                        header="Update failed"
                        key={this.state.status}
                    />);
            case CONSTANT.MODAL_QUERY_ERROR:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content={(<div>Failed to Query the Records! {this.state.statusMsg}</div>)}
                        dismissible={true}
                        header="Query failed"
                        key={this.state.status}
                    />);
            case CONSTANT.MODAL_REQUEST_ERROR:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content={(<div>Request Failed! {this.state.statusMsg}</div>)}
                        dismissible={true}
                        header="Request failed"
                        key={this.state.status}
                    />);
            case CONSTANT.MODAL_SYSTEM_ERROR:
                Logger.logInfo("MODAL_SYSTEM_ERROR ");
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content={(<div>Request Failed! {this.state.statusMsg}</div>)}
                        dismissible={true}
                        header="System Error"
                        key={this.state.status}
                    />);
            case CONSTANT.MODAL_EMPTY_COMMENTS:
                return (
                    <PolarisV3FlashBar 
                        type="error"
                        content={(<div>Please provide comment</div>)}
                        dismissible={true}
                        header="Comments failed"
                        key={this.state.status}
                    />);

            case CONSTANT.FEEDBACK_EXPORTING:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="pending">Exporting your trades</StatusIndicator>
                            <Spin/>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_EXPORT_SUCCESS:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="success">Export Succeessful</StatusIndicator>
                            <div>Congratulations, your file has been successfully exported</div>
                        </SpaceBetween>
                    </Box>
                );
            case CONSTANT.FEEDBACK_EXPORT_ERROR:
                return (
                    <Box textAlign='center' padding='xl'>
                        <SpaceBetween direction='vertical' size='m'>
                            <StatusIndicator type="warning"> Export failed</StatusIndicator>
                            <div>Items failed to Export! {FormatData. formatFailedUpdatedBatchIntoBrowserMessage(this.state.statusMsg)}</div>
                        </SpaceBetween>
                    </Box>
                );
            default:
                return;
        }
    };



    render() {
        Logger.logInfo("Main Render:" + JSON.stringify(this.state.elementSelected));
        let columnDefinitions = [];
        let sortableColumns = [];
        let visibleColumnOption = [];
        let firstOption = {};
        firstOption['label'] = 'Properties';
        let filterOptions = [];
        let contentSelectorOptions = [];

        VISIBLE_COLUMN_OPTIONS_OPEN_TRADE.forEach((column) => {

            let columnDefinitionsItem = {};
            let sortableColumnsItem = {};
            let filterColumnOptionItem = {};
            columnDefinitionsItem['id'] = column;
            columnDefinitionsItem['header'] = column;

            //Display Error for Value Date in Past
            if (column === CONSTANT.TR_COL_RECORD_STATUS) {
                columnDefinitionsItem['cell'] = (item => <StatusComponent valueDate={item['Value Date']} exportDate={item['Export Date']} isItemDisabled={isItemDisabled(item, this.state.user)}/>);
                //Format Display Amount
            } else if (column === CONSTANT.TR_COL_NOTIONAL) {
                columnDefinitionsItem['cell'] = (item => <div style={{color: "#b3b3b3"}}><Box color={isItemDisabled(item, this.state.user) ? "inherit" : ""} textAlign="right">{FormatData.formatDisplayAmount(item[column])}</Box></div>);
            } else if (column === CONSTANT.TR_COL_USD_NOTIONAL) {
                columnDefinitionsItem['cell'] = (item => <div style={{color: "#b3b3b3"}}><Box color={isItemDisabled(item, this.state.user) ? "inherit" : ""} textAlign="right">{FormatData.formatUSDDisplayAmount(item[column])}</Box></div>);
            } else if (column === 'File Attached'){
                columnDefinitionsItem['cell'] = (item => {
                    const value =  hasDocumentAttached(item[CONSTANT.EI_COL_TRADE_ID],
                        this.state.recordIdToDocumentIdMapping) ? 'Yes' : 'No';
                    return  <div style={{color: "#b3b3b3"}}><Box color={isItemDisabled(item, this.state.user) ? "inherit" : ""}>{value}</Box></div>;
                })
            } else {
                columnDefinitionsItem['cell'] = (item => <div style={{color: "#b3b3b3"}}><Box color={isItemDisabled(item, this.state.user) ? "inherit" : ""}>{item[column]}</Box></div>);
            }

            //Ignore the Derived columns for Sorting and Filtering
            if (column !== CONSTANT.TR_COL_RECORD_STATUS && column !== 'File Attached') {
                //Sortable Columns
                sortableColumnsItem['id'] = column;
                sortableColumnsItem['field'] = column;
                sortableColumns.push(sortableColumnsItem);

                //Filtering Options:
                filterColumnOptionItem['propertyLabel'] = column;
                filterColumnOptionItem['propertyKey'] = column;
                filterColumnOptionItem['groupValuesLabel'] = column + " values";
                filterColumnOptionItem['values'] = [];
                filterOptions.push(filterColumnOptionItem);
            }

            columnDefinitionsItem['width'] = 150;
            columnDefinitionsItem['minWidth'] = '100px';
            columnDefinitions.push(columnDefinitionsItem);

            //Content Selector Columns
            let contentSelectorItem = {};
            let hidden_option_open_trade = localStorage.getItem("USER_HIDDEN_OPTION_OPEN_TRADE") === null
                ? HIDDEN_OPTIONS_TRADE : JSON.parse(localStorage.getItem("USER_HIDDEN_OPTION_OPEN_TRADE"));
            if (hidden_option_open_trade.indexOf(column) !== -1) {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = false;
            } else {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = true;
            }

            contentSelectorOptions.push(contentSelectorItem);
        });

        firstOption['options'] = contentSelectorOptions;
        visibleColumnOption.push(firstOption);

        /**
         * Display the Single Upload Document Module.
         */
        if (this.state.singleUploadVisible === true) {
            return <SingleDocumentModule id={this.state.elementSelected[0][CONSTANT.PR_FX_COL_TRADE_ID]}
                                         handleDismissSingleUpload ={this.handleDismissSingleUpload}
            />
        }

        return (
            <div>
                {this.state.documentVisible === true && this.state.documentData.length > 0 &&
                <QueryDocumentData distributions={this.state.documentData}
                                   handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}
                />}
                {this.state.documentVisible === true && this.state.documentData.length === 0 &&
                <NoDocumentModal handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}/>}
                <TradeDataContentBlock distributions={this.state.distributions}
                                        feedbackMessage={this.getFeedbackMessage()}
                                        loading={this.state.loading}
                                        valueDate={this.state.valueDate}
                                        currencyPair={this.state.currencyPair}
                                        currencyPairList={this.state.currencyPairList}
                                        currencyPairDisabled={this.state.currencyPairDisabled}
                                        newComment={this.state.newComment}
                                        userComments={this.state.userComments}
                                        commentsList={this.state.commentsList}
                                        elementSelected={this.state.elementSelected}
                                        contentSelectorOptions={visibleColumnOption}
                                        sortableColumns={sortableColumns}
                                        columnDefinitions={columnDefinitions}
                                        filterOptions={filterOptions}
                                        userGroup={this.state.userGroup}
                                        commentVisible={this.state.commentVisible}
                                        tradeSummaryVisible={this.state.tradeSummaryVisible}
                                        platform={this.state.platform}
                                        onSelectionChange={this.onSelectionChange}
                                        onRowClick={this.onRowClick}
                                        handleViewClick={this.handleViewClick}
                                        handleCheckoutClick={this.handleCheckoutClick}
                                        handleUpdateClick={this.handleUpdate}
                                        handleValueChange={this.handleValueChange}
                                        handleExportClick={this.handleExportClick}
                                        handleManualExportClick={this.handleManualExportClick}
                                        handleViewCommentClick={this.handleViewCommentClick}
                                        handleDismissClick={this.handleDismissClick}
                                        handleAddCommentClick={this.handleAddCommentClick}
                                        handleCommentUpdate={this.handleCommentUpdate}
                                        handleTradeSummaryDismissClick = {this.handleTradeSummaryDismissClick}
                                        handleTradeSummaryExportClick = {this.handleTradeSummaryExportClick}
                                        checkoutConfirmModalVisible={this.state.checkoutConfirmModalVisible}
                                        handleCheckoutConfirmDismissClick={this.handleCheckoutConfirmDismissClick}
                                        handleCheckoutConfirmClick={this.handleCheckoutConfirmClick}
                                        onContentSelectionChange={this.onContentSelectionChange}
                                        handleQueryDocument={this.handleQueryDocument}
                                        recordIdToDocumentIdMapping={this.state.recordIdToDocumentIdMapping}
                                        //Single Document Upload
                                        handleSingleDocumentClick={this.handleSingleDocumentClick}
                    />
        </div>
        );
    }
}

/**
 * The disabledCell function inspects an item within a cell to verify if the cell should be disabled.
 * 
 * @param item
 *      The item to be inspected to verify if a cell should be disabled (show gray font)
 * @returns {JSX.Element}
 *      Returns a JSX element that either has its font changed to gray (if it's determined to be disabled), or its original cell (if it's determined to be enabled).
 */
const isItemDisabled = (item, user) => item[CONSTANT.TR_COL_CHECKEDOUTUSER] !== user && item[CONSTANT.TR_COL_CHECKEDOUTUSER] !== undefined && item[CONSTANT.TR_COL_STATUS] !== 'Approved';

export default QueryTradeData;
