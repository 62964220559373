import React, {Component} from 'react';
import '@amzn/awsui-global-styles/polaris.css';
import {
    ColumnLayout,
    Button,
    Form,
    FormField,
    Modal,
    DatePicker,
    ButtonDropdown,
    Container,
    Header,
    SpaceBetween,
    Box
} from "@amzn/awsui-components-react";
import CONSTANT from "../utils/constant";
import APIClient from "../utils/apiClient";
import Logger from "../utils/logger";
import {
    PAGE_SELECTOR_OPTIONS,
    PAGE_SIZE,
    VISIBLE_COLUMN_OPTIONS_HISTORICAL,
    HIDDEN_OPTIONS_TRADE,
    DOWNLOAD_FILE_TYPE
} from "../config/table";
import moment from "moment-timezone";
import FormatData from "../utils/formatData";
import {getStage} from "../utils/environment";
import {
    getCurrentDateInDateString,
    getFirstDayOfTheMonthInDateString
} from "../payment/common/paymentRecordTableCommon";
import {DOWNLOAD_FILE_PAYMENT_DELIMITER} from "../config/file";
import xlsxParser from "../utils/xlsxParser";
import DataContentTable from '../component/dataContentTable';
import {NoDocumentModal} from "../document/noDocumentModal";
import {QueryDocumentData} from "../document/queryDocumentData";
import {hasDocumentAttached} from "../document/upload/documentUtils";
import SingleDocumentModule from "../document/upload/singleDocumentModule";
import {PolarisV3FlashBar} from "../component/dataDisplay";
import {queryDocuments} from "../payment/common/modal/documentQueryHelper";


/*
 * Display the records in this trade data table
 */
class TradeDataContentBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elementSelected: [],
            selectedDistributions: [],
            filteringText: '',
            filteringTokens: [],
            pageSize: PAGE_SIZE,
            contentSelectorOptions: this.props.contentSelectorOptions,
            wrapLines: false,
            sortingDescending: true,
            sortingColumn: CONSTANT.TR_COL_USD_NOTIONAL,
            documentData:[],
            documentVisible: false,
            singleUploadVisible: false,
        }
        // Logger.logInfo("distributions: " + this.state.distributions);
        Logger.logInfo("props distributions: " + this.props.distributions);
    }

    /**
     * Function for row click in the data table.
     * The selectedDistributions is the row selected.
     */
    onRowClick = ({detail}) => {
        let newSelectedDistributions = [...this.state.selectedDistributions];
        if (newSelectedDistributions.includes(detail.item)) {
            newSelectedDistributions = newSelectedDistributions.filter(x => x !== detail.item)
        } else {
            newSelectedDistributions.push(detail.item)
        }
        this.setState({selectedDistributions: newSelectedDistributions});
    };

    onWrapLinesChange({detail}) {
        this.setState({
            wrapLines: detail.value
        });
    }

    onSelectionChange({detail}) {
        this.setState({
            selectedDistributions: detail.selectedItems
        });
        Logger.logInfo("selectedDistributions element changed: " + JSON.stringify(this.state.selectedDistributions))
    }

    onPropertyFilteringChange({detail}) {
        this.setState({
            filteringTokens: detail.tokens
        });
    }

    clearFilter() {
        this.setState({
            filteringText: '',
            filteringTokens: []
        });
    }

    //Handle Confirm Download Click
    handleConfirmDownloadClick = (ifDownloadAll, fileType, recordIdToDocumentIdMapping) => {
        Logger.logInfo("handleConfirmDownloadClick fileType: " + fileType);
        let fileName = "TRS_Trade_Download" + FormatData.formatNewFileDate() + '.' + fileType;
        let delimiter = DOWNLOAD_FILE_PAYMENT_DELIMITER;
        let header = VISIBLE_COLUMN_OPTIONS_HISTORICAL;
        let enrichedTrades;
        if (ifDownloadAll) {
            enrichedTrades = JSON.parse(JSON.stringify(this.props.distributions));
            enrichedTrades = enrichedTrades.map(trade => {
                trade['File Attached'] = hasDocumentAttached(trade[CONSTANT.EI_COL_TRADE_ID],
                    recordIdToDocumentIdMapping) ? 'Yes' : 'No';
                return trade;
            })
        } else {
            enrichedTrades = JSON.parse(JSON.stringify(this.state.selectedDistributions));
            enrichedTrades = enrichedTrades.map(trade => {
                trade['File Attached'] =  hasDocumentAttached(trade[CONSTANT.EI_COL_TRADE_ID],
                    recordIdToDocumentIdMapping) ? 'Yes' : 'No';
                return trade;
            })
        }
        if (fileType === 'xlsx') {
            xlsxParser.writeFile(fileName, delimiter, enrichedTrades, header, fileType);
        } else if (fileType === 'csv') {
            xlsxParser.writeFileANSI(fileName, delimiter, enrichedTrades, header);
        }
    }

    // queryDocuments for the queryDocumentData page.
    handleQueryDocument = async () => {
        this.setState({
            status: CONSTANT.MODAL_QUERYING
        });

        const selectedIds = this.state.selectedDistributions.map(item => item[CONSTANT.PR_FX_COL_TRADE_ID])
        const result = await queryDocuments(selectedIds);

        switch (result.status) {
            case CONSTANT.RESPONSE_SUCCESS:
                this.setState({
                    documentData: result.documentData,
                    documentVisible: true,
                });
                Logger.logInfo("Query Document Data succeeded.");
                break;
            case CONSTANT.RESPONSE_ERROR:
                Logger.logError("Error Query Document Data = " + this.state.selectedDistributions.toString());
                this.setState({
                    documentVisible: false,
                    status: CONSTANT.MODAL_QUERY_ERROR
                });
                break;
            case CONSTANT.RESPONSE_SYSTEM_ERROR:
                Logger.logError("System Error when Query Document Data = " + this.state.selectedDistributions.toString());
                this.setState({
                    status: CONSTANT.MODAL_SYSTEM_ERROR,
                    statusMsg: JSON.stringify(result.errorMessage)
                });
                break;
            case CONSTANT.MODAL_OTHER_ERROR:
                Logger.logError("Other System Error when Query Document Data");
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Other System Error when query document data records."
                });
                break;
            default:
                Logger.logError("Unhandled response status:", result.status);
                this.setState({
                    status: CONSTANT.MODAL_OTHER_ERROR,
                    statusMsg: "Received unknown response status"
                });

        }
    }

    handleDismissDocumentModalClick = () => {
        this.setState({
            documentVisible: false,
        })
    }

    handleDismissSingleUpload = () => {
        this.setState({
            singleUploadVisible: false,
        })
    }
    handleSingleDocumentClick = () => {
        this.setState({
            singleUploadVisible: true
        })
    }

    render() {

        let selectionDownloadDisabled = true;
        let viewDocumentButton = <></>;
        let singleUploadDisabled = true;
        if (this.state.selectedDistributions.length > 0) {
            selectionDownloadDisabled = false;
            // logic for viewing documents
            const selectedIds = this.state.selectedDistributions.map(item => item[CONSTANT.PR_FX_COL_TRADE_ID]);
            let shouldDisplayViewDocumentButton = false;
            selectedIds.forEach(recordId => {
                if (hasDocumentAttached(recordId, this.props.recordIdToDocumentIdMapping)) {
                    shouldDisplayViewDocumentButton = true;
                }
            });
            viewDocumentButton = shouldDisplayViewDocumentButton ?
                <Button onClick={() =>this.handleQueryDocument()}>View Documents</Button> : "";
        }

        const searchBar =
            <Container>
                <SpaceBetween direction="vertical" size="m">
                    <ColumnLayout columns={3}>
                        <FormField
                            description="Specify the range start of the search (Will use 00:00:00 of the selected date)"
                            label="From Date*"
                        >
                            <DatePicker
                                onChange={event => {
                                    this.props.handleValueChange('fromValue', event.detail.value);
                                    Logger.logInfo("fromValue after change: " + this.props.fromValue)
                                }}
                                value={this.props.fromValue}
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"/>
                        </FormField>
                        <FormField
                            description="Specify the range end of the search (Will use 00:00:00 of the selected date)"
                            label="To Date*"
                        >
                            <DatePicker
                                onChange={event => {
                                    this.props.handleValueChange('toValue', event.detail.value);
                                    Logger.logInfo("toValue after change: " + this.props.toValue)
                                }}
                                value={this.props.toValue}
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"/>
                        </FormField>
                        <Box float="left">
                            <SpaceBetween direction="vertical" size="xs">
                                <Button variant="primary"
                                        iconName="angle-right"
                                        iconAlign="right"
                                        onClick={() => {
                                            this.props.handleQueryClick(CONSTANT.INDEX_STRING_HISTORICAL_TRADE_BY_EXECUTION_DATE);
                                            this.setState({
                                                selectedDistributions: []
                                            })
                                        }}
                                >Search by Execution Date</Button>
                                <Button variant="primary"
                                        iconName="angle-right"
                                        iconAlign="right"
                                        onClick={() => {this.props.handleQueryClick(CONSTANT.INDEX_STRING_HISTORICAL_TRADE_BY_EXPORT_DATE);
                                            this.setState({
                                                selectedDistributions: []
                                            })}
                                        }
                                >Search by Exported Date </Button>
                            </SpaceBetween>
                        </Box>
                    </ColumnLayout>
                </SpaceBetween>
            </Container>;

        const tableHeader =
            <SpaceBetween direction="vertical" size="m">
                {this.props.feedbackMessage}
                {searchBar}
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button disabled={singleUploadDisabled}
                                onClick={() => this.handleSingleDocumentClick()}>Single Upload Document</Button>
                        <ButtonDropdown
                            items={DOWNLOAD_FILE_TYPE}
                            onItemClick={(event) => this.handleConfirmDownloadClick(true, event.detail.id, this.props.recordIdToDocumentIdMapping)}
                            disabled={false}>
                            Download All
                        </ButtonDropdown>
                        <ButtonDropdown
                            items={DOWNLOAD_FILE_TYPE}
                            onItemClick={(event) => this.handleConfirmDownloadClick(false, event.detail.id, this.props.recordIdToDocumentIdMapping)}
                            disabled={selectionDownloadDisabled}>
                            Download Selected
                        </ButtonDropdown>
                        {viewDocumentButton}
                    </SpaceBetween>
                </Box>
            </SpaceBetween>;

        // Defines the default sorting state of the Clouscape table. The table will be sorted
        // by USD Notional in descending order by default.
        const defaultSortingState = {
            defaultState: {
                sortingColumn: {
                    sortingField: CONSTANT.TR_COL_USD_NOTIONAL
                },
                isDescending: true
            }
        }

        const viewDocumentModal = this.state.documentVisible === true && this.state.documentData.length > 0 &&
            <QueryDocumentData distributions={this.state.documentData}
                               handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}/>
        const noDocumentModal = this.state.documentVisible === true && this.state.documentData.length === 0 &&
                <NoDocumentModal handleDismissDocumentModalClick={this.handleDismissDocumentModalClick}/>


        /**
         * Display the Single Upload Document Module.
         */
        if (this.state.singleUploadVisible === true) {
            const recordId = this.state.selectedDistributions[0][CONSTANT.PR_FX_COL_TRADE_ID];
            return <SingleDocumentModule id={recordId}
                                         handleDismissSingleUpload ={this.handleDismissSingleUpload}
            />
        }

        return (
            <Container header={
                <Header
                    variant="h2"
                >
                    Query Historical Trade Data
                </Header>
            }>
                    <ColumnLayout columns={1}>
                        {viewDocumentModal}
                        {noDocumentModal}
                        <DataContentTable
                            wrapLines={false}
                            onRowClick={this.onRowClick}
                            defaultSortingState={defaultSortingState}
                            columnDefinitions={this.props.columnDefinitions}
                            distributions={this.props.recordIdToDocumentIdMapping ? this.props.distributions : []}
                            header={tableHeader}
                            filterOptions={this.props.filterOptions}
                            trackBy="System ID"
                            elementSelected={this.state.selectedDistributions}
                            onSelectionChange={this.onSelectionChange.bind(this)}
                            pageSelectorOptions={PAGE_SELECTOR_OPTIONS}
                            contentSelectorOptions={this.state.contentSelectorOptions}
                        />
                    </ColumnLayout>
                </Container>);
    }
}

/*
 *  Query  History Trade Parent element that wraps all the above component
 *  Fetches the Trade Data available for Review.
 */
class QueryHistoryTradeData extends Component {
    constructor(props) {
        super(props);
        moment.tz.setDefault(CONSTANT.MOMENT_PST_TIMEZONE);
        this.state = {
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: "",
            loading: false,
            distributions: [],
            recordIdToDocumentIdMapping: {},
            recordIdToDocumentIdMappingLoaded: false,
            //this needs to be passed in as props, Authentication method should return this value.,'TTECH-TRADE-REVIEWER-ACCESS'
            userGroup: localStorage.getItem(CONSTANT.USER_GROUP_NAME),
            fromValue: getFirstDayOfTheMonthInDateString(),
            toValue: getCurrentDateInDateString(1),
        }
    }

    handleValueChange = (name, value) => {
        this.setState({[name]: value});
    };


    handleQuery = (indexString) => {
        Logger.logInfo('handleQuery');
        var parameter = {};

        this.setState({
            loading: true,
            status: CONSTANT.MODAL_QUERYING
        });
        Logger.logInfo('fromValue: ' + this.state.fromValue + ' toValue: ' + this.state.toValue);

        // Based on the UserType pass the parameters, not passing userGroup in param will retrieve all types of trades.
        // Passing specific userGroup in param will filter out certain types of trades based on backend configuration.
        // Following groups get to review all types of trades:
        // Reviewers, Prod Admins, Developers in Non-Prod Env
        if (this.state.userGroup.includes(CONSTANT.USER_GROUP_REVIEWER) ||
            this.state.userGroup.includes(CONSTANT.USER_GROUP_MO) ||
            this.state.userGroup.includes(CONSTANT.USER_GROUP_PROD_ADMIN) ||
            (this.state.userGroup.includes(CONSTANT.USER_GROUP_DEVELOPER) &&
                getStage() !== 'prod')) {
            parameter = {
                indexString: indexString,
                fromValue: this.state.fromValue,
                toValue: this.state.toValue
            }
        } else {
            parameter = {
                indexString: indexString,
                userGroup: this.state.userGroup,
                fromValue: this.state.fromValue,
                toValue: this.state.toValue
            }
        }

        Logger.logInfo('ISO parameter: ' + JSON.stringify(parameter));
        APIClient.invoke('GET', 'trade', parameter,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    Logger.logInfo("Data reterived: " + JSON.stringify(data));
                    switch (data.status) {
                        case CONSTANT.RESPONSE_SUCCESS:
                            Logger.logInfo(JSON.stringify(data));
                            this.setState({
                                status: CONSTANT.MODAL_UPDATE_SUCCESS,
                                distributions: data.data
                            });
                            Logger.logInfo("After distributions:" + JSON.stringify(this.state.distributions))
                            break;
                        case CONSTANT.RESPONSE_ERROR:
                            Logger.logError("Backend crashed when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_QUERY_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data));
                            break;
                        case CONSTANT.RESPONSE_SYSTEM_ERROR:
                            Logger.logError("Backend crashed when Querying data records ");
                            this.setState({
                                status: CONSTANT.MODAL_SYSTEM_ERROR,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        case CONSTANT.MODAL_REQUEST_ERROR:
                            this.setState({
                                status: CONSTANT.MODAL_REQUEST_ERROR,
                                distributions: data.data,
                                statusMsg: JSON.stringify(data['errorData'])
                            });
                            Logger.logInfo("Data error: " + JSON.stringify(data['errorData']));
                            break;
                        default:
                    }
                } else {
                    Logger.logError("Backend crashed when  Querying data records ");
                    this.setState({
                        status: CONSTANT.MODAL_OTHER_ERROR,
                        statusMsg: "Other System error when Querying Data Records."
                    });
                }
            });
    }

    componentDidMount() {
        Logger.logInfo('componentDidMount');
        this.handleQuery(CONSTANT.INDEX_STRING_HISTORICAL_TRADE_BY_EXECUTION_DATE);

        //Query RecordIdToDocumentIdMapping
        const paramGetRecordIdToDocumentIdMapping = {dataType:
            CONSTANT.QUERY_STRING_PARAM_TYPE_DOCUMENT_ID_MAPPING};

        APIClient.invoke('GET', '/attachDocuments/documentData', paramGetRecordIdToDocumentIdMapping,
            undefined,
            (err, data) => {
                this.setState({loading: false});
                if (!err) {
                    this.setState({
                        recordIdToDocumentIdMapping: data,
                        recordIdToDocumentIdMappingLoaded: true,
                    });
                    Logger.logInfo("recordIdToDocumentIdMaping state:" + JSON.stringify(this.state.recordIdToDocumentIdMapping))
                } else {
                    Logger.logError("Other Error when Querying recordIdToDocumentIdMapping information");
                    if (this.state.status !== CONSTANT.MODAL_OTHER_ERROR) {
                        this.setState({
                            status: CONSTANT.MODAL_OTHER_ERROR,
                            statusMsg: "Other System Error when Querying recordIdToDocumentIdMapping information"
                        });
                    }
                }
            });
    }

    // set the Normal status
    recoverAddButtonEmptyState = () => {
        this.setState({
            status: CONSTANT.MODAL_EMPTY
        });
    };

    //render the Modal message
    getFeedbackMessage = () => {
        Logger.logInfo("Modal Message " + this.state.status);
        switch (this.state.status) {
            case CONSTANT.MODAL_QUERYING:
                return <PolarisV3FlashBar 
                    type="success"
                    loading={true}
                    content={<>Busy fetching data... Please wait.</>}
                    dismissible={false}
                    key={this.state.status}
                />;
            case CONSTANT.MODAL_OTHER_ERROR:
                return <PolarisV3FlashBar 
                    type="error"
                    loading={false}
                    header="Error"
                    content={<>{this.state.statusMsg}</>}
                    dismissible={true}
                    key={this.state.status}
                />;
            case CONSTANT.MODAL_QUERY_ERROR:
                return (
                    <Modal
                        content={<div>Failed to Query the Records! {this.state.statusMsg}</div>}
                        visible={true}
                        header="Query failed"
                        onDismiss={this.recoverAddButtonEmptyState}
                    />);
            case CONSTANT.MODAL_REQUEST_ERROR:
                return (
                    <Modal
                        content={<div>Request Failed! {this.state.statusMsg}</div>}
                        visible={true}
                        header="Update failed"
                        onDismiss={this.recoverAddButtonEmptyState}
                    />);
            case CONSTANT.MODAL_SYSTEM_ERROR:
                Logger.logInfo("MODAL_SYSTEM_ERROR ");
                return (
                    <Modal
                        content={<div>Request Failed! {this.state.statusMsg}</div>}
                        visible={true}
                        header="System Error"
                        onDismiss={this.recoverAddButtonEmptyState}
                    />);
            default:
                return;
        }
    };

    render() {
        let columnDefinitions = [];
        let sortableColumns = [];
        let filterOptions = [];
        let contentSelectorOptions = [];
        let visibleColumnOption = [];
        let firstOption = {};
        firstOption['label'] = 'Properties';

        VISIBLE_COLUMN_OPTIONS_HISTORICAL.forEach((column) => {
            //generate Column Definition
            let columnDefinitionsItem = {};
            columnDefinitionsItem['id'] = column;
            columnDefinitionsItem['header'] = column;

            //Format Display Amount
            if (column === CONSTANT.TR_COL_NOTIONAL) {
                columnDefinitionsItem['cell'] = (item => <Box textAlign="right">{FormatData.formatDisplayAmount(item[column])}</Box>);
            } else if (column === CONSTANT.TR_COL_USD_NOTIONAL) {
                columnDefinitionsItem['cell'] = (item => <Box textAlign="right">{FormatData.formatUSDDisplayAmount(item[column])}</Box>);
            } else if (column === 'File Attached'){
                columnDefinitionsItem['cell'] = (item => hasDocumentAttached(item[CONSTANT.EI_COL_TRADE_ID],
                    this.state.recordIdToDocumentIdMapping) ? 'Yes' : 'No')
            } else {
                columnDefinitionsItem['cell'] = (item => item[column]);
            }

            columnDefinitionsItem['width'] = 150;
            columnDefinitionsItem['minWidth'] = '100px';
            columnDefinitions.push(columnDefinitionsItem);

            if (column !== 'File Attached') {
                let sortableColumnsItem = {};
                sortableColumnsItem['id'] = column;
                sortableColumnsItem['field'] = column;
                sortableColumns.push(sortableColumnsItem);

                let filterColumnOptionItem = {};
                filterColumnOptionItem['propertyLabel'] = column;
                filterColumnOptionItem['propertyKey'] = column;
                filterColumnOptionItem['groupValuesLabel'] = column + " values";
                filterColumnOptionItem['values'] = [];
                filterOptions.push(filterColumnOptionItem);
            }

            let contentSelectorItem = {};
            if (HIDDEN_OPTIONS_TRADE.indexOf(column) !== -1) {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = false;
            } else {
                contentSelectorItem['id'] = column;
                contentSelectorItem['label'] = column;
                contentSelectorItem['editable'] = true;
                contentSelectorItem['visible'] = true;
            }

            contentSelectorOptions.push(contentSelectorItem);
        });
        firstOption['options'] = contentSelectorOptions;
        visibleColumnOption.push(firstOption);

        return (
            <div>
                <Form>
                    <br/>
                    <TradeDataContentBlock distributions={this.state.distributions}
                                           contentSelectorOptions={visibleColumnOption}
                                           sortableColumns={sortableColumns}
                                           feedbackMessage={this.getFeedbackMessage()}
                                           columnDefinitions={columnDefinitions}
                                           filterOptions={filterOptions}
                                           handleQueryClick={this.handleQuery}
                                           handleValueChange={this.handleValueChange}
                                           defaultFromValue={this.state.fromValue}
                                           defaultToValue={this.state.toValue}
                                           fromValue={this.state.fromValue}
                                           toValue={this.state.toValue}
                                           recordIdToDocumentIdMapping={this.state.recordIdToDocumentIdMapping}
                    />
                </Form>
            </div>);
    }
}
export default QueryHistoryTradeData;
